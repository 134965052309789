.react-split {
    flex: 1;
    height: 100%;
    position: relative;
    width: 100%;

    &__pane {
        height: 100%;
        position: absolute;
        white-space: normal;
        width: 100%;
        overflow: hidden;
    }

    &__sash {
        height: 100%;
        position: absolute;
        top: 0;
        transition: background-color 0.1s;
        width: 100%;
        z-index: 2;

        &--disabled {
            pointer-events: none;
        }

        &--vertical {
            cursor: col-resize;
        }

        &--horizontal {
            cursor: row-resize;
        }

        &-content {
            width: 100%;
            height: 100%;

            &--active {
                background-color: #175ede;
            }
        }
    }

    &--dragging {
        &.react-split--vertical {
            cursor: col-resize;
        }
        &.react-split--horizontal {
            cursor: row-resize;
        }
    }
}

body.react-split--disabled {
    user-select: none;
}

.split-sash-content {
    width: 100%;
    height: 100%;

    &.split-sash-content-vscode {
        &.split-sash-content-active {
            background-color: #175ede;
        }
    }

    &.split-sash-content-sublime {

    }
}